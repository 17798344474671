import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'contexts/AppContext';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Button from 'components/Shared/Button';

import { jobService } from 'services/jobService';
import { retrieverService } from 'services/retrieverService';

import { UNAUTHORIZED_MESSAGES } from 'constants/appConstants';

import './SearchForm.scss';

const SearchForm = ({
    buttonText = "Search",
    disabled = false,
    handleSubmitFunc = null,
    resetForm = false,
}) => {
    const navigate = useNavigate();
    const { state, submitFormSuccess, setPageLoadingState, updateResultsSuccess, fetchJobTitlesSuccess, resetResultsState, logout } = useAppContext();

    const [jobTitle, setJobTitle] = useState("");
    const [jobLocation, setJobLocation] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [mostImportantSkill, setMostImportantSkill] = useState("");
    const [showContacted, setShowContacted] = useState(1);

    useEffect(() => {
        if (state.formValues.jobId && !resetForm) {
            setJobTitle(state.formValues.jobTitle);
            setJobLocation(state.formValues.jobLocation);
            setJobDescription(state.formValues.jobDescription);
            setMostImportantSkill(state.formValues.mostImportantSkill);
            setShowContacted(state.formValues.showContacted);
        }
    }, [state.formValues.jobId, resetForm]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (handleSubmitFunc!==null) {
            handleSubmitFunc({ jobTitle, jobLocation, jobDescription, mostImportantSkill, showContacted })
        } else {
            try {
                navigate('/search-results');
              
                submitFormSuccess({ jobTitle, jobLocation, jobDescription, mostImportantSkill, showContacted });
                
                setPageLoadingState(true);
                resetResultsState();
                const response = await jobService.createJob({ jobTitle, jobLocation, jobDescription, mostImportantSkill, userId: state.userId , showContacted});
                submitFormSuccess({ jobId: response.jobId });
                const jobs = await jobService.fetchJobTitles();
                const { documents } = await retrieverService.retrieveProfiles(response.jobId, false, mostImportantSkill, showContacted);
                setPageLoadingState(false);

                fetchJobTitlesSuccess(jobs.data);
                updateResultsSuccess(documents);
            } catch (err) {
                if (UNAUTHORIZED_MESSAGES.includes(err.message)) {
                    localStorage.clear();
                    logout();
                    navigate('/');
                }
            } finally {
                setPageLoadingState(false);
            }
        }
    };

    return (
        <form className="search-form-wrapper" onSubmit={handleSubmit}>
            <label>
                Job Name:
                <input className="form-input" type="text" value={jobTitle} onChange={(event) => setJobTitle(event.target.value)} required={true} disabled={disabled} />
            </label>
            <label>
                Job Location:
                <input className="form-input" type="text" value={jobLocation} onChange={(event) => setJobLocation(event.target.value)} required={true} disabled={disabled} />
            </label>
            <div className="filter-container">
                <input type="checkbox" id="show-contacted" name="showContacted" checked={showContacted} onChange={(event) => setShowContacted(event.target.checked)} />
                Show Candidates contacted in last 2 months
            </div>
            <label>
                <p className="most-important-skill">Most Important Skill:
                    <div className="info-icon">
                        <span>i</span>
                        <div className="tooltip">Adding a skill is optional. If you add a skill, only profiles who have a direct match for that skill keyword will be shown, potentially reducing the amount of matched profiles. If this field is empty, we will infer the keyword based on the job title and description.</div>
                    </div>
                </p>
                <Autocomplete
                    disablePortal
                    freeSolo
                    value={mostImportantSkill}
                    options={state.skills}
                    onChange={(_, value) => setMostImportantSkill(value)}
                    renderInput={(params) => <TextField {...params} name="skill" onChange={(e) => setMostImportantSkill(e.target.value)} />}
                />
            </label>
            <label>
                Job Description (Title, Description, Requirements, Responsibilities):
                <textarea rows={20} type="text" name="jobDescription" value={jobDescription} onChange={(event) => setJobDescription(event.target.value)} required={true} disabled={disabled} />
            </label>
            <Button text={buttonText} type="submit" className="green centered" />
        </form >
    )
};

export default SearchForm;
