import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'contexts/AppContext';

import { retrieverService } from 'services/retrieverService';

import Navigation from 'components/Shared/Navigation';
import SearchForm from 'components/Shared/SearchForm';

import { ReactComponent as DownIcon } from 'assets/down-icon.svg';
import { ReactComponent as UpIcon } from 'assets/up-icon.svg';

import Table from 'components/Table';

import { UNAUTHORIZED_MESSAGES } from 'constants/appConstants';

import './SearchResults.scss';

const SearchResults = () => {
    const navigate = useNavigate();
    const { state, setPageLoadingState, updateResultsSuccess, resetResultsState, logout, submitFormSuccess } = useAppContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const [page, setPage] = useState(0);

    const ITEMS_PER_PAGE = 200;
    const MAX_PAGE = state.results.length / ITEMS_PER_PAGE - 1;

    const getCurrentPageData = (data) => {
        if (data) {
            const startIndex = page * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;

            if (endIndex < data.length) {
                return data.slice(0, endIndex);
            }

            return data.slice(0, data.length)
        }
    };

    const handleLoadMoreClick = () => {
        setPage(prevState => prevState + 1);
    };

    const getIsLoadMoreBtnVisible = () => page < MAX_PAGE;

    const handleIconClick = () => setIsExpanded(!isExpanded);

    const handleFormSubmit = async (data) => {
        try {



            submitFormSuccess(data);

            setPageLoadingState(true);
            resetResultsState();
            setPage(0);
            const { documents } = await retrieverService.retrieveProfiles(state.formValues.jobId, true, data.mostImportantSkill, data.showContacted);
            setPageLoadingState(false);

            updateResultsSuccess(documents);
        } catch (err) {
            if (UNAUTHORIZED_MESSAGES.includes(err.message)) {
                localStorage.clear();
                logout();
                navigate('/');
            }
        } finally {
            setPageLoadingState(false);
        }
    };

    return (
        <div className="search-results-wrapper">
            <Navigation />
            <div className="global-section-with-navigation search-results-container">
                <div className="title-wrapper">
                    <h1>DB Search for <span>{state.formValues.jobTitle}</span></h1>
                    {isExpanded ? <UpIcon onClick={handleIconClick} /> : <DownIcon onClick={handleIconClick} />}
                </div>

                {isExpanded && <SearchForm buttonText="Search Again" disabled={true} handleSubmitFunc={handleFormSubmit} />}

                <div className="table-wrapper">
                    <Table
                        data={getCurrentPageData(state.results)}
                        isDataLoading={state.isPageLoading}
                        isLoadMoreBtnVisible={getIsLoadMoreBtnVisible()}
                        handleLoadMoreClick={handleLoadMoreClick}
                    />
                </div>
            </div>
        </div>
    )
};

export default SearchResults;
