export const HEAD_CELLS = [
    { key: 1, value: 'Name' },
    { key: 2, value: 'Title' },
    { key: 3, value: 'Skill Frequency' },
    { key: 4, value: 'Location' },
    { key: 5, value: 'YOE' },
    { key: 6, value: 'Avg. Job Duration' },
    { key: 8, value: '' },
];

export const ASSESSMENT_REJECTION_REASONS_KEYS = {
    "LOCATION_MISMATCH": "LOCATION_MISMATCH",
    "LACK_OF_RELEVANT_SKILLS": "LACK_OF_RELEVANT_SKILLS",
    "OVERQUALIFIED": "OVERQUALIFIED",
    "UNDERQUALIFIED": "UNDERQUALIFIED",
    "EMPTY_PROFILE": "EMPTY_PROFILE",
    "NON_SOLICITATION_LIST": "NON_SOLICITATION_LIST",
    "OTHER": "OTHER"
};

export const ASSESSMENT_REJECTION_REASONS = {
    [ASSESSMENT_REJECTION_REASONS_KEYS.LOCATION_MISMATCH]: "Location mismatch",
    [ASSESSMENT_REJECTION_REASONS_KEYS.LACK_OF_RELEVANT_SKILLS]: "Lack of relevant skills",
    [ASSESSMENT_REJECTION_REASONS_KEYS.OVERQUALIFIED]: "Overqualified (YOE)",
    [ASSESSMENT_REJECTION_REASONS_KEYS.UNDERQUALIFIED]: "Underqualified (YOE)",
    [ASSESSMENT_REJECTION_REASONS_KEYS.EMPTY_PROFILE]: "Empty profile / Bot",
    [ASSESSMENT_REJECTION_REASONS_KEYS.NON_SOLICITATION_LIST]: "Non-solicitation list",
    [ASSESSMENT_REJECTION_REASONS_KEYS.OTHER]: "Other",
};

export const ASSESSMENT_REJECTION_REASONS_DEFAULT_VALUES = {
    [ASSESSMENT_REJECTION_REASONS_KEYS.LOCATION_MISMATCH]: false,
    [ASSESSMENT_REJECTION_REASONS_KEYS.LACK_OF_RELEVANT_SKILLS]: false,
    [ASSESSMENT_REJECTION_REASONS_KEYS.OVERQUALIFIED]: false,
    [ASSESSMENT_REJECTION_REASONS_KEYS.UNDERQUALIFIED]: false,
    [ASSESSMENT_REJECTION_REASONS_KEYS.EMPTY_PROFILE]: false,
    [ASSESSMENT_REJECTION_REASONS_KEYS.NON_SOLICITATION_LIST]: false,
    [ASSESSMENT_REJECTION_REASONS_KEYS.OTHER]: false,
};
