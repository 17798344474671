import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useAppContext } from 'contexts/AppContext';
import { jobService } from 'services/jobService';
import { skillsService } from 'services/skillsService';

import Home from 'pages/Home';
import SearchResults from 'pages/SearchResults';
import Login from 'pages/Login';

import './App.scss';

function App() {
  const { state } = useAppContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { fetchJobTitlesSuccess, fetchAllSkillsSuccess } = useAppContext();

  useEffect(() => {
    if (state.isAuthenticated) {
      jobService.fetchJobTitles().then(response => {
        fetchJobTitlesSuccess(response.data);
      });
      skillsService.fetchAllSkills().then(response => {
        fetchAllSkillsSuccess(response.data);
      });
    }
  }, [state.isAuthenticated]);

  return (
    <div className="app-wrapper">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={state.isAuthenticated ? <Home /> : <Login username={username} password={password} setUsername={setUsername} setPassword={setPassword} />} />
          <Route exact path="/search-results" element={state.isAuthenticated ? <SearchResults /> : <Login username={username} password={password} setUsername={setUsername} setPassword={setPassword} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
