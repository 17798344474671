import { BASE_URL, HTTP_STATUS_CODES } from "constants/appConstants";

const queryStringBuilder = params => '?' + Object.entries(params).map((x) => `${x[0]}=${x[1]}`).join('&');

const buildRequest = (method, body, contentType) => {
    const token = localStorage.getItem("token");

    return {
        method,
        headers: {
            ...(contentType && { 'Content-Type': contentType }),
            ...(token && { 'Authorization': token })
        },
        body
    }
};

const buildUrl = (...path) => {
    let url = path
        .filter(x => x && typeof (x) == 'string')
        .join('/');

    let lastElement = path.pop();
    if (lastElement) {
        url += queryStringBuilder(lastElement);
    }

    return url;
};


const responseHandler = async res => {
    if (!res.ok) {
        if (res.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            let response = await res.json();
            throw response;
        }

        throw await res.json();
    }

    return res.json();
};

export const requester = (endpoint, params) => ({
    GET: () => fetch(buildUrl(BASE_URL, endpoint, params), buildRequest('GET')).then(responseHandler),
    POST: (body) => fetch(buildUrl(BASE_URL, endpoint, params), buildRequest('POST', JSON.stringify(body), 'application/json')).then(responseHandler),
    PUT: (body) => fetch(buildUrl(BASE_URL, endpoint, params), buildRequest('PUT', JSON.stringify(body), 'application/json')).then(responseHandler),
    DELETE: () => fetch(buildUrl(BASE_URL, endpoint, params), buildRequest('DELETE')).then(responseHandler),
});
