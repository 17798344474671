import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'contexts/AppContext';
import { retrieverService } from 'services/retrieverService';

import Button from 'components/Shared/Button';

import { UNAUTHORIZED_MESSAGES } from 'constants/appConstants';

import './Navigation.scss';

const Navigation = () => {
    const navigate = useNavigate();
    const { state, submitFormSuccess, setPageLoadingState, updateResultsSuccess, resetResultsState, logout } = useAppContext();

    const handlePastSearchClick = async (event, searchResult) => {
        try {
            event.preventDefault();
            
            setPageLoadingState(true);
            resetResultsState();
            const { documents, job } = await retrieverService.retrievePastSearchProfiles(searchResult.jobId);
            setPageLoadingState(false);
            
            updateResultsSuccess(documents);
            submitFormSuccess(job);
            
            navigate('/search-results');
        } catch (err) {
            if (UNAUTHORIZED_MESSAGES.includes(err.message)) {
                localStorage.clear();
                logout();
                navigate('/');
            }
        } finally {
            setPageLoadingState(false);
        }
    };

    return (
        <div className="navigation-wrapper">
            <Button text="New Search" className={"green"} handleClick={() => navigate('/')} />
            <h2 className="global-white">Past Searches</h2>
            <ul className="past-searches-list">
                {state.pastSearches?.map((searchResult, idx) => (
                    <li key={idx} onClick={(event) => handlePastSearchClick(event, searchResult)} className="global-white"><span>{searchResult.jobTitle}</span></li>
                ))}
            </ul>
        </div>
    )
};

export default Navigation;
