import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'contexts/AppContext';

import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Button from 'components/Shared/Button';

import { assessmentService } from 'services/assessmentService';

import { ASSESSMENT_REJECTION_REASONS_DEFAULT_VALUES, HEAD_CELLS, ASSESSMENT_REJECTION_REASONS, ASSESSMENT_REJECTION_REASONS_KEYS } from './TableConstants';
import { ASSESSMENT_TYPES } from 'constants/assessmentConstants';
import { UNAUTHORIZED_MESSAGES } from 'constants/appConstants';

import { ReactComponent as ThumbsUpDefault } from 'assets/thumbs-up-default.svg';
import { ReactComponent as ThumbsUpGreen } from 'assets/thumbs-up-green.svg';
import { ReactComponent as ThumbsDownDefault } from 'assets/thumbs-down-default.svg';
import { ReactComponent as ThumbsDownRed } from 'assets/thumbs-down-red.svg';

import greenHouseImage from 'assets/greenHouse.png';
import greenHouse2Image from 'assets/greenHouse2.png';

import './Table.scss';

const Table = ({ data, isDataLoading, isLoadMoreBtnVisible, handleLoadMoreClick }) => {
    const navigate = useNavigate();

    const { state, updateResultAssessmentSuccess, logout } = useAppContext();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [feedbackValue, setFeedbackValue] = useState('');
    const [selectedElement, setSelectedElement] = useState(null);
    const [rejectionReasons, setRejectionReasons] = useState(ASSESSMENT_REJECTION_REASONS_DEFAULT_VALUES);

    const handleIconClick = (event, element) => {
        setIsPopupOpen(true);
        setAnchorEl(event.target);
        setSelectedElement(element);
        setRejectionReasons(element.AssessmentInfo?.RejectionReasons || ASSESSMENT_REJECTION_REASONS_DEFAULT_VALUES);
    };

    const handleClickAway = () => {
        setIsPopupOpen(false);
        setAnchorEl(null);
        setFeedbackValue('');
        setSelectedElement(null);
        setRejectionReasons(ASSESSMENT_REJECTION_REASONS_DEFAULT_VALUES);
    };

    const handleCheckboxClick = (key) => {
        let currState = { ...rejectionReasons };

        if (key === ASSESSMENT_REJECTION_REASONS_KEYS.OTHER) {
            currState = { ...ASSESSMENT_REJECTION_REASONS_DEFAULT_VALUES };
        } else {
            currState[ASSESSMENT_REJECTION_REASONS_KEYS.OTHER] = false;
            setFeedbackValue('');
        }

        currState[key] = !currState[key];

        setRejectionReasons(currState);
    };

    const handleFeedbackSubmit = async (type, element) => {
        const assessmentID = element.AssessmentInfo.assessmentID;
        try {
            switch (type) {
                case ASSESSMENT_TYPES.POSITIVE:
                    await assessmentService.updateAssessment(assessmentID, {
                        publicID: element.publicID,
                        jobID: state.formValues.jobId,
                        userID: state.userId, // TODO
                        successfulAssessment: 1,
                        rejectionReason: '',
                    });
                    updateResultAssessmentSuccess({ assessmentID, publicID: element.publicID, jobID: state.formValues.jobId, SuccessfulAssessment: 1 });
                    break;
                case ASSESSMENT_TYPES.NEGATIVE:
                    await assessmentService.updateAssessment(assessmentID, {
                        publicID: element.publicID,
                        jobID: state.formValues.jobId,
                        userID: state.userId, // TODO
                        successfulAssessment: -1,
                        rejectionReason: feedbackValue,
                        rejectionReasons,
                    });
                    updateResultAssessmentSuccess({ assessmentID, publicID: element.publicID, jobID: state.formValues.jobId, SuccessfulAssessment: -1, RejectionReason: feedbackValue, RejectionReasons: rejectionReasons });
                    break;
                default:
                    break;
            }

            handleClickAway();
        } catch (err) {
            if (UNAUTHORIZED_MESSAGES.includes(err.message)) {
                localStorage.clear();
                logout();
                navigate('/');
            }
        }
    };

    const adjustKeys = (data) => data.map(element => ({
        name: element.FlagATS == 1 ? (
            element.lastContacted == 1 ?
            <Tooltip component className="name-wrapper" title="Candidate contacted in the last 2 months">
                <img className="green-house-image" src={greenHouse2Image} alt="green-house" />  <a href={`https://www.linkedin.com/in/${element['publicID']}`} className='with-label' target='_blank' rel="noreferrer">{element.Name}</a>
            </Tooltip> :
            
            <Tooltip component className="name-wrapper" title="This LinkedIn URL is associated with a GH candidate.">
            <img className="green-house-image" src={greenHouseImage} alt="green-house" />  <a href={`https://www.linkedin.com/in/${element['publicID']}`} className='with-label' target='_blank' rel="noreferrer">{element.Name}</a>
            </Tooltip>
            ) : <a href={`https://www.linkedin.com/in/${element['publicID']}`} target='_blank'>{element.Name}</a>,
        role: element.CurrentPositionPosition.join(" "),
        skillFrequency: element.SkillFrequencies.length > 0 ?
            <p className="skill">{element.SkillFrequencies.map(x => <span key={`${x.skill}-${x.frequency}`}>{x.skill} ({x.frequency}); </span>)}</p> :
            "",
        location: element.Location,
        tyore: <Typography variant='body1'>{element.TotalYearsOfExperience ? `${element.TotalYearsOfExperience.toFixed(1)} yrs.` : ''} { element.employmentStatus != "employed" ? ( element.employmentStatus == "opentowork" ? <Tooltip component title="Candidate is Open To Work"><span className="open-to-work-label">#OpenToWork</span></Tooltip> : <Tooltip component title="Candidate is Unemployed"><span className="unemployed-label">#Unemployed</span></Tooltip>):<></>}</Typography> ,
        avgJobDuration: <Typography variant='body1'>{element.avgJobDuration ? `${(element.avgJobDuration / 12).toFixed(1)} yrs.` : ''} {element.MonthsSinceLastJobChange <= 6 && <Tooltip component title="Person recently changed jobs"><span className="last-change-label">{element.MonthsSinceLastJobChange}mo ago</span></Tooltip>}</Typography>,
        feedback: element.AssessmentInfo?.SuccessfulAssessment === 1 ? 1 : element.AssessmentInfo?.SuccessfulAssessment === -1 ? -1 : null
    }));

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Fragment>
                <table className="table">
                    <thead>
                        <tr>
                            {HEAD_CELLS.map(cell => (
                                <td key={cell.key}>{cell.value}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isDataLoading ?
                            <Box className="global-loader-container" >
                                <CircularProgress />
                            </Box>
                            : !isDataLoading && data.length === 0 ?
                                <Box className="global-loader-container" >
                                    <Typography>No data to show</Typography>
                                </Box>
                                : adjustKeys(data).map((element, elementIdx) => (
                                    <tr key={elementIdx}>
                                        {Object.keys(element).map((key, idx) => (
                                            <Fragment key={idx}>
                                                {idx === Object.keys(element).length - 1 ?
                                                    <td>
                                                        <div className="flex-items">
                                                            {element[key] === 1 ? <div className="icon-wrapper"><ThumbsUpGreen /></div> : <div className="icon-wrapper"><ThumbsUpDefault onClick={() => handleFeedbackSubmit(ASSESSMENT_TYPES.POSITIVE, data[elementIdx])} /></div>}
                                                            {element[key] === -1 ? <div className="icon-wrapper"><ThumbsDownRed /> </div> : <div className="icon-wrapper"><ThumbsDownDefault onClick={(event) => handleIconClick(event, data[elementIdx])} /></div>}
                                                        </div>
                                                    </td>
                                                    : <td key={idx}>{element[key]}</td>}
                                            </Fragment>
                                        ))}
                                    </tr>
                                ))}

                    </tbody>
                    <BasePopup id="base-popup" open={isPopupOpen} anchor={anchorEl} placement="bottom-end">
                        <div className="global-popup-form">
                            <FormGroup className="rejection-reason-options">
                                {Object.entries(rejectionReasons).map((entries, idx) => (
                                    <FormControlLabel key={idx} control={<Checkbox onChange={() => handleCheckboxClick(entries[0])} checked={entries[1]} />} label={ASSESSMENT_REJECTION_REASONS[entries[0]]} />
                                ))}
                            </FormGroup>
                            {rejectionReasons[ASSESSMENT_REJECTION_REASONS_KEYS.OTHER] && <textarea placeholder='Write...' rows={8} value={feedbackValue} onChange={(event) => setFeedbackValue(event.target.value)}></textarea>}
                            <Button text="Save" handleClick={() => handleFeedbackSubmit(ASSESSMENT_TYPES.NEGATIVE, selectedElement)} className="green no-margin" />
                        </div>
                    </BasePopup>
                </table>
                { isLoadMoreBtnVisible && <Button text="Load More" handleClick={() => handleLoadMoreClick()} className="green load-more-btn" /> }
            </Fragment>
        </ClickAwayListener>
    )
};

export default Table;
