import React, { createContext, useContext, useEffect, useReducer } from 'react';

// Initial State
const initialState = {
    formValues: {
        jobId: '',
        jobTitle: '',
        jobLocation: '',
        jobDescription: '',
        mostImportantSkill: '',
    },
    results: [],
    pastSearches: [],
    skills: [],
    isPageLoading: false,
    isAuthenticated: false,
    userId: '',
};

// Action Types
const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';
const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS';
const UPDATE_RESULT_ASSESSMENT_SUCCESS = 'UPDATE_RESULT_ASSESSMENT_SUCCESS';
const PERSIST_STATE_SUCCESS = 'PERSIST_STATE';
const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
const FETCH_JOB_TITLES_SUCCESS = 'FETCH_JOB_TITLES_SUCCESS';
const FETCH_ALL_SKILLS_SUCCESS = 'FETCH_ALL_SKILLS_SUCCESS';
const RESET_RESULTS_STATE_SUCCESS = 'RESET_RESULTS_STATE_SUCCESS';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// Reducer Function
const appReducer = (state, action) => {
    switch (action.type) {
        case FORM_SUBMIT_SUCCESS:
            return { ...state, formValues: { ...state.formValues, ...action.payload } };
        case FETCH_RESULTS_SUCCESS:
            return { ...state, results: [...action.payload] };
        case UPDATE_RESULT_ASSESSMENT_SUCCESS:
            return {
                ...state, results: state.results.map(x => {
                    if (action.payload.assessmentID === x.AssessmentInfo.assessmentID) {
                        return { ...x, AssessmentInfo: { ...action.payload } };
                    }

                    return x;
                })
            }
        case SET_PAGE_LOADING:
            return { ...state, isPageLoading: action.payload };
        case FETCH_JOB_TITLES_SUCCESS:
            return { ...state, pastSearches: action.payload };
        case FETCH_ALL_SKILLS_SUCCESS:
            return { ...state, skills: action.payload };
        case LOGIN_SUCCESS:
            return { ...state, isAuthenticated: true, userId: action.payload };
        case PERSIST_STATE_SUCCESS:
            return { ...state, ...action.payload };
        case RESET_RESULTS_STATE_SUCCESS:
            return { ...state, results: [] }
        case LOGOUT_SUCCESS:
            return { ...initialState };
        default:
            return state;
    }
};

// Create App Context
const AppContext = createContext();

// Custom hook to access the Context
const useAppContext = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('useAppContext must be used within a AppContextProvider!')
    }

    return context;
};

// Provider component
const AppContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState);

    // Actions
    const submitFormSuccess = (payload) => dispatch({ type: FORM_SUBMIT_SUCCESS, payload });
    const updateResultsSuccess = (payload) => dispatch({ type: FETCH_RESULTS_SUCCESS, payload });
    const updateResultAssessmentSuccess = (payload) => dispatch({ type: UPDATE_RESULT_ASSESSMENT_SUCCESS, payload });
    const fetchJobTitlesSuccess = (payload) => dispatch({ type: FETCH_JOB_TITLES_SUCCESS, payload });
    const fetchAllSkillsSuccess = (payload) => dispatch({ type: FETCH_ALL_SKILLS_SUCCESS, payload });
    const setPageLoadingState = (payload) => dispatch({ type: SET_PAGE_LOADING, payload });
    const resetResultsState = () => dispatch({ type: RESET_RESULTS_STATE_SUCCESS });
    const login = (userId) => dispatch({ type: LOGIN_SUCCESS, payload: userId });
    const logout = () => dispatch({ type: LOGOUT_SUCCESS });

    useEffect(() => {
        const savedState = localStorage.getItem('state');
        if (savedState) {
            dispatch({ type: PERSIST_STATE_SUCCESS, payload: JSON.parse(savedState) });
        }
    }, []);

    useEffect(() => {
        if (JSON.stringify(state) !== JSON.stringify(initialState)) {
            localStorage.setItem('state', JSON.stringify(state));
        }
    }, [state]);

    return (
        <AppContext.Provider value={{ state, submitFormSuccess, updateResultsSuccess, updateResultAssessmentSuccess, setPageLoadingState, fetchJobTitlesSuccess, fetchAllSkillsSuccess, resetResultsState, login, logout }}>
            {children}
        </AppContext.Provider>
    )
};

export { AppContextProvider, useAppContext };
