import React from 'react';

import './Button.scss';

const Button = ({
    text,
    className,
    handleClick,
    props,
}) => (
    <button className={`btn ${className}`} onClick={handleClick} {...props}>{text}</button>
);

export default Button;
