export const BASE_URL = '/api';

export const HTTP_STATUS_CODES = {
    UNAUTHORIZED: 401
};

export const UNAUTHORIZED_MESSAGES = [
    'Token has expired',
    'Token is missing',
    'Invalid token'
];
