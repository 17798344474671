import React  from 'react';

import Navigation from 'components/Shared/Navigation';
import SearchForm from 'components/Shared/SearchForm';

import './Home.scss';

const Home = () => (
    <div className="home-wrapper">
        <Navigation />
        <div className="global-section-with-navigation home-container">
            <h1>New Search</h1>
            <SearchForm resetForm={true} />
        </div>
    </div>
);

export default Home;
