export const api = {
    userLogin: () => 'users/login',
    jobs: () => 'jobs',
    job: (jobId) => `jobs/${jobId}`,
    skills: () => `skills`,
    assessments: () => 'assessments',
    assessment: (assessmentId) => `assessments/${assessmentId}`,
    retriever: (job_id, filterDownVoted=false, mostImportantSkill="", showContacted=true) => `retriever/${job_id}?filterDownVoted=${filterDownVoted}&mostImportantSkill=${mostImportantSkill}&showContacted=${showContacted}`,
    retrieverPastSearchProfiles: (job_id) => `retriever/${job_id}/past-search`
};
