import React from 'react';

import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';

import { userService } from 'services/userService';

import './Login.scss';

const Login = ({
    username,
    password,
    setUsername,
    setPassword,
}) => {
    const navigate = useNavigate();
    const { login } = useAppContext();

    const handleLogin = async () => {
        try {
            const response = await userService.login(username, password);
            localStorage.setItem("token", response.token);
            login(response.userId);
            navigate('/');
        } catch (err) {
            alert("Invalid credentials");
        } 
    };

    return (
        <div className="login-wrapper">
            <div className="form-wrapper">
                <label>
                    Username:
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <br />
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <br />
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;
